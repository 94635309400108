import React from "react";
import { FaRegHandPointRight } from "react-icons/fa6";

export default function WhyChooseUs() {
  return (
    <div className=" px-14 py-20 text-font bg-slate-50 mobile:px-4 largeK:px-24">
      <div className=" flex justify-between items-center tablet:flex-wrap">
        <div className=" flex-1 tablet:flex-none tablet:w-full tablet:mb-20">
          <h2 className=" text-3xl font-bold text-center mb-10 largeK:text-5xl tablet:text-2xl underline underline-offset-4 decoration-amber-500 mobile:text-xl">
            Why Choose Us ?
          </h2>
          <div className=" text-4xl font-semibold mb-10 largeK:text-6xl tablet:text-3xl mobile:text-2xl">
            Unparalleled{" "}
            <span className=" font-bold text-amber-500">Excellence</span> in
            Cleaning and Organization.
          </div>
          <p className=" largeK:text-2xl tablet:text-sm mobile:text-xs">
            Our <span className=" font-bold text-amber-500">dedicated</span>{" "}
            team goes above and beyond to ensure every corner of your space is
            immaculately cleaned and thoughtfully organized. With attention to
            detail and a commitment to{" "}
            <span className=" font-bold text-amber-500">exceeding</span> your
            expectations, we transform your Space into a welcoming retreat.{" "}
            <span className=" font-bold text-amber-500">
              Trust TG (Canada) Cleaning
            </span>{" "}
            to deliver exceptional results and create an unforgettable
            experience for you and your guests.
          </p>
        </div>
        <div className=" flex-1 pl-20 tablet:flex-none tablet:w-full tablet:pl-0">
          <h2 className=" text-3xl font-bold text-center mb-16 largeK:text-5xl tablet:text-2xl underline underline-offset-4 decoration-amber-500 mobile:text-xl">
            We Promise You
          </h2>
          <div className=" flex justify-start items-center flex-wrap">
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Freshness and Hygiene</div>
            </div>
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Timeliness</div>
            </div>
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Professionalism</div>
            </div>
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Surface Protection</div>
            </div>
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Customer Satisfaction</div>
            </div>
            <div className=" flex items-center mr-7 mb-5 w-[200px] largeK:w-[400px] laptop:w-[300px]">
              <div className=" text-3xl mr-4 text-amber-500 largeK:text-6xl tablet:text-2xl">
                <FaRegHandPointRight />
              </div>
              <div className=" tablet:text-sm font-semibold largeK:text-3xl">Spotless Cleanliness</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
