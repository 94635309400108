import React, { useState } from "react";
import Modal from "react-modal";
import app from "./firebaseconfiguration";
import { getDatabase, push, ref } from "firebase/database";

Modal.setAppElement("#root");

const QuoteModal = ({ isOpen, closeModal }) => {
  const database = getDatabase(app);

  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    address: "",
    phoneNumber: "",
    city: "",
    province: "",
    postal: "",
    service: "",
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.fullName ||
      !formData.email ||
      !formData.address ||
      !formData.phoneNumber ||
      !formData.city ||
      !formData.province ||
      !formData.service
    ) {
      // Alert the user to fill up all required fields or pick a product
      setErrorMessage("Please fill up all required fields or pick a product.");
      return;
    }

    // Add code to handle form submission (e.g., sending data to backend)
    try {
      // Push the form data to the database
      await push(ref(database, "bookingInfo"), formData);
      await push(ref(database, "customerdetails"), formData);

      window.alert(
        "We have recieved your Quote, we will reach out to you as soon as posible"
      );
    } catch (error) {
      window.alert(
        "There is an Error sending your Quote pleae try again",
        error
      );
    }

    setFormData({
      fullName: "",
      companyName: "",
      email: "",
      address: "",
      phoneNumber: "",
      city: "",
      province: "",
      postal: "",
      service: "",
    });
  };

  const services = [
    "AirBnb Services",
    "Office and Commercial Cleaning",
    "Carpet Cleaning",
    "Residential Cleaning",
    "Move in & Move out Cleaning",
    "Window Cleaning",
    "Event Venue Cleaning",
    "Medical & Healthcare facilities",
    "School Cleaning",
    "Janitorial Service",
    "Organization Services",
  ];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Quote Modal"
    >
      <div className=" relative select-none">
        <h2 className=" smallphones:text-lg mobile:text-xl text-center font-bold text-amber-500 text-3xl underline pt-8 mb-8 largeK:pt-16 largeK:text-6xl largeK:mb-16">
          Get a Quote
        </h2>
        <form onSubmit={handleSubmit}>
          <div className=" largeK:mb-16">
            <div className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10">
              Name
            </div>
            <div className=" px-14 py-4 flex justify-between items-center tablet:p-4 mobile:p-0 mobile:flex-col">
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder=" Fullname"
                value={formData.fullName}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Name(optional)"
                value={formData.companyName}
                onChange={handleInputChange}
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
            </div>
          </div>
          <div className=" largeK:mb-16">
            <div className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10">
              Contact Info
            </div>
            <div className=" px-14 py-4 flex justify-between items-center tablet:p-4 mobile:p-0 mobile:flex-col">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone No"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder=" Email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
            </div>
          </div>
          <div className=" largeK:mb-16">
            <div
              htmlFor="phoneNumber"
              className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10"
            >
              Location
            </div>
            <div className=" px-14 py-4 tablet:p-4 mobile:p-0">
              <div className=" flex justify-between items-center mb-5 mobile:flex-col">
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder=" Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
              </div>
              <div className="flex justify-between items-center mobile:flex-col">
                <input
                  type="text"
                  id="province"
                  name="province"
                  placeholder="Province"
                  value={formData.province}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
                <input
                  type="text"
                  id="postal"
                  name="postal"
                  placeholder="Postal Code"
                  value={formData.postal}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
              </div>
            </div>
          </div>
          <div className=" largeK:mb-16">
            <div
              htmlFor="service"
              className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10"
            >
              Service
            </div>
            <div className=" px-14 py-4 flex justify-between items-center tablet:p-4 mobile:p-0 mobile:flex-col">
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              >
                <option value="">Select Service</option>
                {services.map((service, index) => (
                  <option key={index} value={service}>
                    {service}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className=" text-center">
            <button
              type="submit"
              className=" mt-10 mobile:w-[100%] bg-amber-500 p-3 rounded-lg text-white font-bold w-[20vw] largeK:text-3xl largeK:py-6"
            >
              Check Out
            </button>
          </div>
        </form>
        <button
          onClick={closeModal}
          className=" smallphones:text-xs smallphones:p-1 mobile:text-sm mobile-p-2 bg-red-500 text-white  p-3 rounded-lg absolute top-0 right-4 largeK:font-bold largeK:text-2xl"
        >
          Close
        </button>
        <div className="text-red-500 text-sm">{errorMessage}</div>
      </div>
    </Modal>
  );
};

export default QuoteModal;
