import React from "react";
import AbtImg from "../asset/undraw_clean_up_re_504g.svg";
import { MdCleaningServices } from "react-icons/md";
import { FaAirFreshener } from "react-icons/fa";
import { IoTimer } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function AboutUsSection() {
  return (
    <div className=" px-14 text-font mb-20 tablet:relative sm:px-4 largeK:px-24">
      <div>
        <div className=" text-center text-5xl largeK:text-7xl font-bold mb-28 tablet:text-4xl mobile:text-2xl">
          <h1>About Us</h1>
        </div>
        <div className=" flex justify-between items-center">
          <div className=" flex-1 tablet:absolute tablet:bottom-0">
            <img
              src={AbtImg}
              alt=""
              className="w-full h-full tablet:w-[50vw]"
            />
          </div>
          <div className=" flex-[1.5] tablet:text-right">
            <h2 className=" text-4xl font-bold mb-10 largeK:text-6xl largeK:mb-20 tablet:text-3xl">
              Our <span className="text-amber-500">Commitment</span> to
              Excellence.
            </h2>
            <p className=" mb-10 text-lg largeK:text-2xl largeK:mb-20 tablet:text-base mobile:text-sm">
              At Topman Galaxy, we prioritize spotless cleanliness, timely
              service, and a refreshing atmosphere. With a dedicated focus on
              hygiene, we ensure every space we touch is left impeccably clean.
            </p>
            <div className=" mb-12">
              <div className=" flex items-center mb-6 largeK:mb-14 font-semibold text-lg tablet:flex-row-reverse">
                <MdCleaningServices className=" text-3xl largeK:text-6xl text-amber-500" />
                <span className=" ml-4 largeK:text-4xl tablet:mr-4 sm:text-sm">
                  Spotless Cleanliness
                </span>
              </div>
              <div className=" flex items-center mb-6 largeK:mb-14 font-semibold text-lg tablet:flex-row-reverse">
                <FaAirFreshener className=" text-3xl largeK:text-6xl text-amber-500" />
                <span className=" ml-4 largeK:text-4xl tablet:mr-4 sm:text-sm">
                  Freshness and Hygiene
                </span>
              </div>
              <div className=" flex items-center mb-6 largeK:mb-14 font-semibold text-lg tablet:flex-row-reverse">
                <IoTimer className=" text-3xl largeK:text-6xl text-amber-500" />
                <span className=" ml-4 largeK:text-4xl tablet:mr-4 sm:text-sm">
                  Timeliness
                </span>
              </div>
            </div>
            <Link to="/aboutus">
              <button className=" sm:text-lg smallphones:text-xs border-2 bg-teal rounded-md p-3 text-2xl font-bold text-white largeK:text-4xl largeK:p-6 hover:bg-opacity-60">
                More About Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
