import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BsCart4 } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import app from "./firebaseconfiguration";
import { getDatabase, push, ref } from "firebase/database";

Modal.setAppElement("#root");

const ProductModal = ({ isOpen, closeModal }, item) => {
  const database = getDatabase(app);

  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    address: "",
    phoneNumber: "",
    city: "",
    province: "",
    postal: "",
    cartItems: [], // Initialize cartItems as an empty array
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.fullName ||
      !formData.email ||
      !formData.address ||
      !formData.phoneNumber ||
      !formData.city ||
      !formData.province ||
      cartItems.length === 0
    ) {
      setErrorMessage("Please fill up all required fields or pick a product.");
      return;
    }

    try {
      // Include cartItems in formData before pushing to the database
      const formDataWithCart = { ...formData, cartItems: cartItems };
      await push(ref(database, "orders"), formDataWithCart); // Push the formData with cartItems to the "orders" node


      window.alert(
        `
          Your order has been placed successfully.
          Thanks for choosing TG Canada Cleaning Services for your
          cleaning essential products.
          Our service included product delivery, organizing,
          and inventory management.
          Full price quotation will be sent to the email provided
        `
      );
    } catch (error) {
      console.error("Error placing the order:", error);
      window.alert("There was an error placing your order. Please try again.");
    }

    setFormData({
      fullName: "",
      companyName: "",
      email: "",
      address: "",
      phoneNumber: "",
      city: "",
      province: "",
      postal: "",
    });
    setCartItems([]); // Clear cartItems state
    localStorage.removeItem("cart"); // Clear cartItems from local storage

    closeModal();
  };

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCartItems);
  }, [isOpen]);

  const handleDeleteItem = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const totalPrice = parseFloat(
    cartItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2)
  );

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity++;
    setCartItems(updatedCartItems);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
  };

  const handleDecrement = (index) => {
    if (cartItems[index].quantity > 1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[index].quantity--;
      setCartItems(updatedCartItems);
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Product Modal"
    >
      <div className=" relative">
        <h2 className=" flex items-center justify-center smallphones:text-lg mobile:text-xl text-center font-bold text-amber-500 text-3xl underline pt-8 mb-8 largeK:pt-16 largeK:text-6xl largeK:mb-16">
          <div>Checkout Your Cart</div> <BsCart4 />
        </h2>
        <div className=" mb-4">
          {cartItems.map((item, index) => (
            <div
              key={index}
              className=" mobile:relative mobile:text-xs mobile:flex-col mobile:justify-normal mobile:items-start tablet:text-sm largeK:text-3xl flex justify-between items-center p-4 mobile:px-2 border-b-2 border-font text-font font-bold"
            >
              <div className=" mr-3 mobile:mr-0">
                <img
                  src={item.image}
                  alt=""
                  className="tablet:w-[50px] tablet:h-[50px] largeK:w-[100px] largeK:h-[100px] w-[70px] h-[70px] bg-teal rounded-2xl"
                />
              </div>
              <div className=" flex justify-between items-center w-full">
                <div className=" truncate overflow-hidden mobile:w-[150px]">
                  {item.title}
                </div>
                <div className=" mobile:hidden border bg-gray-200 select-none">
                  <span
                    onClick={() => handleDecrement(index)}
                    className="cursor-pointer bg-teal px-2 mobile:px-1 py-2 font-bold text-white mobile:text-xs"
                  >
                    -
                  </span>
                  <span className="px-2">{item.quantity}</span>
                  <span
                    onClick={() => handleIncrement(index)}
                    className="cursor-pointer bg-teal px-2 mobile:px-1 py-2 font-bold text-white mobile:text-xs"
                  >
                    +
                  </span>{" "}
                </div>
                <div className=" text-clip overflow-hidden">
                  <span>$</span>
                  {parseFloat(item.price * item.quantity).toFixed(2)}
                </div>
                <button
                  onClick={() => handleDeleteItem(index)}
                  className=" text-white bg-red-500 hover:bg-red-950 flex justify-between items-center font-bold p-2 rounded-xl mobile:absolute top-2 right-3"
                >
                  <div className=" mobile:hidden">Remove </div>
                  <MdDeleteForever />
                </button>
              </div>
              <div className="hidden mobile:block border bg-gray-200 mt-5">
                <span
                  onClick={() => handleDecrement(index)}
                  className="cursor-pointer rounded-md bg-teal px-2 mobile:px-3 py-2 font-bold text-white mobile:text-xs"
                >
                  -
                </span>
                <span className="px-2">{item.quantity}</span>
                <span
                  onClick={() => handleIncrement(index)}
                  className="cursor-pointer rounded-md bg-teal px-2 mobile:px-3 py-2 font-bold text-white mobile:text-xs"
                >
                  +
                </span>{" "}
              </div>
            </div>
          ))}
          <div className=" mb-10 mobile:text-xs tablet:text-sm largeK:text-3xl">
            <div>
              <div className=" my-4">
                Total Item: <span className=" font-bold">{totalItems}</span>
              </div>
              <div>
                Total Price: <span className=" font-bold">${totalPrice}</span>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="border">
          <div className=" largeK:mb-16">
            <div className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10">
              Name
            </div>
            <div className=" px-14 py-4 flex justify-between items-center tablet:p-4 mobile:p-0 mobile:flex-col">
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder=" Fullname"
                value={formData.fullName}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Name(optional)"
                value={formData.companyName}
                onChange={handleInputChange}
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
            </div>
          </div>
          <div className=" largeK:mb-16">
            <div className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10">
              Contact Info
            </div>
            <div className=" px-14 py-4 flex justify-between items-center tablet:p-4 mobile:p-0 mobile:flex-col">
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone No"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder=" Email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
              />
            </div>
          </div>
          <div className=" largeK:mb-16">
            <div
              htmlFor="phoneNumber"
              className=" smallphones:text-sm mobile:text-base mobile:font-semibold laptop:text-lg laptop:mb-2 text-font text-xl font-bold mb-4 largeK:pl-14 largeK:text-2xl largeK:mb-10"
            >
              Location
            </div>
            <div className=" px-14 py-4 tablet:p-4 mobile:p-0">
              <div className=" flex justify-between items-center mb-5 mobile:flex-col">
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder=" Address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
              </div>
              <div className="flex justify-between items-center mobile:flex-col">
                <input
                  type="text"
                  id="province"
                  name="province"
                  placeholder="Province"
                  value={formData.province}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] mobile:mb-4 focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
                <input
                  type="text"
                  id="postal"
                  name="postal"
                  placeholder="Postal Code"
                  value={formData.postal}
                  onChange={handleInputChange}
                  required
                  className=" smallphones:text-xs border bg-slate-50 rounded-md py-3 px-2 w-[35vw] mobile:w-[100%] focus:border-amber-500 focus:border-2 outline-none largeK:text-2xl largeK:px-4 largeK:py-5"
                />
              </div>
            </div>
          </div>
          <div className=" text-center">
            <button
              type="submit"
              className=" mt-10 mobile:w-[100%] bg-amber-500 p-3 rounded-lg text-white font-bold w-[20vw] largeK:text-3xl largeK:py-6"
            >
              Check Out
            </button>
          </div>
        </form>
        <button
          onClick={closeModal}
          className=" smallphones:text-xs smallphones:p-1 mobile:text-sm mobile-p-2 bg-red-500 text-white  p-3 rounded-lg absolute top-0 right-4 mobile:right-1 largeK:font-bold largeK:text-2xl"
        >
          <span className=" mobile:hidden">Close</span>{" "}
          <span className=" hidden mobile:block">X</span>
        </button>
        <div className="text-red-500 text-sm">{errorMessage}</div>
      </div>
    </Modal>
  );
};

export default ProductModal;
