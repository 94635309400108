import React, { useState } from "react";
import Header from "../Header/header";
import Footer from "../footer";
import ServiceHero from "./serviceHero";
import Proccess from "./serviceProcess";
import ServicesPage from "./servicesPage";
import QuoteModal from "../quoteModal";

export default function Service(props) {
  const { openproductModal } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" overflow-x-hidden">
      <Header openproductModal={openproductModal} />
      <QuoteModal isOpen={isModalOpen} closeModal={closeModal} />
      <ServiceHero openModal={openModal} />
      <Proccess />
      <ServicesPage openModal={openModal} />
      <Footer />
    </div>
  );
}
