import React from "react";
import app from "../firebaseconfiguration";
import { getDatabase, push, ref, set } from "firebase/database";

export default function ContactForm() {
  const handleSubmit = (event) => {
    event.preventDefault();

    const database = getDatabase(app);
    const contactsRef = ref(database, "contacts");

    const fullName = event.target.elements.fullName.value;
    const email = event.target.elements.email.value;
    const phoneNo = event.target.elements.phoneNo.value;
    const subject = event.target.elements.subject.value;
    const message = event.target.elements.message.value;

    const newContactRef = push(contactsRef);
    set(newContactRef, {
      fullName: fullName,
      email: email,
      phoneNo: phoneNo,
      subject: subject,
      message: message,
    });

    // Optionally, you can reset the form after submission
    event.target.reset();
  };

  return (
    <div>
      <div className=" border-2 border-font p-5 mobile:p-2 rounded-lg">
        <div className=" mb-10 text-amber-500 font-bold text-2xl largeK:text-4xl">
          Send Message
        </div>
        <form className="flex flex-col justify-center" onSubmit={handleSubmit}>
          <div className="justify-between items-center flex-row flex mb-10 mobile:flex-col">
            <input
              type="text"
              placeholder="Full Name"
              required
              name="fullName"
              className="p-3 border-bgcolor border pr-7 rounded-md bg-slate-100 mobile:mb-6 largeK:w-[500px] largeK:text-2xl largeK:h-[80px] mobile:w-[100%]"
            />
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              className="p-3 border-bgcolor border pr-7 rounded-md bg-slate-100 largeK:w-[500px] largeK:text-2xl largeK:h-[80px] mobile:w-[100%]"
            />
          </div>
          <div className="justify-between items-center flex-row flex mb-10 mobile:flex-col">
            <input
              type="tel"
              placeholder="Phone No"
              required
              name="phoneNo"
              className="p-3 border-bgcolor border pr-7 rounded-md bg-slate-100 mobile:mb-6 largeK:w-[500px] largeK:text-2xl largeK:h-[80px] mobile:w-[100%]"
            />
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              className="p-3 border-bgcolor border pr-7 rounded-md bg-slate-100 largeK:w-[500px] largeK:text-2xl largeK:h-[80px] mobile:w-[100%]"
            />
          </div>
          <div>
            <textarea
              placeholder="Type Your Message"
              name="message"
              className="border border-bgcolor w-full px-3 py-5 largeK:h-[300px] h-[200px] bg-slate-100 largeK:text-2xl"
            />
          </div>
          <button
            type="submit"
            className="bg-teal font-bold text-white px-4 py-2 rounded-lg mt-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
