import React from "react";

export default function ServicesPage(props) {
  const { openModal } = props;

  return (
    <div className=" bg-bgcolor p-14 mobile:px-0">
      <div>
        <h2 className=" text-center font-bold text-3xl text-background mb-14 largeK:text-5xl mobile:text-xl">
          TG (CANADA) Cleaning Services
        </h2>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:mr-0 flex-1 mr-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjxFF.image-fx-an-image-that-shows-the-background-image-of-a.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Office & Commercial Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Cultivate a pristine work environment with our Office and
              Commercial Cleaning services, ensuring your workspace reflects the
              professionalism and excellence of your business.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] tablet:relative tablet:justify-normal tablet:items-start flex justify-center items-center flex-row-reverse mb-20">
          <div className=" tablet:flex-auto tablet:relative tablet:ml-0 flex-1 ml-3">
            <img
              src="https://i.im.ge/2024/04/16/ZuIcWS.image-fx-an-image-showing-team-of-cleaner-putting-on-t.jpeg"
              alt=""
              className=" rounded-2xl tablet:w-[100%]"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1">
            <h2 className="  mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 largeK:text-7xl text-font text-4xl font-semibold mb-7 underline decoration-amber-500">
              Carpet Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Experience the revitalizing touch of our Carpet Cleaning
              expertise, bringing new life to your carpets and enhancing the
              ambiance of your space.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:mr-0 flex-1 mr-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjCk6.image-fx-a-loaded-image-that-shows-the-background-imag.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Residential Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Elevate your living spaces with our Residential Cleaning
              solutions, tailored to meet your unique needs and exceed your
              expectations for cleanliness and comfort.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center flex-row-reverse mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:ml-0 flex-1 ml-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjAPz.image-fx-a-loaded-image-that-shows-the-background-imag-1.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Move in & Move out Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Transition seamlessly with our Move in & Move out Cleaning
              services, providing thorough sanitation and organization for a
              smooth transition into your new home or office.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:mr-0 flex-1 mr-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjyqS.image-fx-a-loaded-image-that-shows-the-background-imag-3.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Window Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Let the light shine through with our Window Cleaning services,
              ensuring crystal-clear views and enhancing the aesthetics of your
              property.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center flex-row-reverse mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:ml-0 flex-1 ml-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjwQx.image-fx-a-loaded-image-that-shows-the-background-imag-4.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Event Venue Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Ensure every event leaves a lasting impression with our Event
              Venue Cleaning expertise, creating immaculate settings for
              memorable occasions.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:mr-0 flex-1 mr-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjj8G.image-fx-a-loaded-image-that-shows-the-background-imag-5.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Medical & Healthcare facilities
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Maintain the highest standards of cleanliness and hygiene in
              medical and healthcare facilities with our specialized cleaning
              solutions, prioritizing safety and well-being.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center flex-row-reverse mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:ml-0 flex-1 ml-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjPny.image-fx-a-loaded-image-that-shows-the-background-imag-6.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              School Cleaning
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Nurture a conducive learning environment with our School Cleaning
              services, promoting student health and productivity through
              spotless facilities.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:mr-0 flex-1 mr-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVjNxJ.image-fx-a-loaded-image-that-shows-the-background-imag-7.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Janitorial Service
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Trust our Janitorial Service to keep your premises pristine and
              orderly, allowing you to focus on your core business activities
              with peace of mind.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div className=" tablet:h-[100%] w-[100%] flex justify-center items-center flex-row-reverse mb-20 tablet:relative tablet:justify-normal tablet:items-start">
          <div className=" tablet:flex-auto tablet:relative tablet:ml-0 flex-1 ml-3 ">
            <img
              src="https://i.im.ge/2024/05/08/ZVj6La.image-fx-a-loaded-image-that-shows-the-background-imag-9.jpeg"
              alt=""
              className=" tablet:w-[100%] rounded-2xl"
            />
          </div>
          <div className=" mobile:w-[100%] tablet:flex-auto tablet:absolute tablet:bottom-0 tablet:bg-black tablet:bg-opacity-40 tablet:left-0 tablet:p-3 tablet:rounded-b-2xl flex-1 ">
            <h2 className=" mobile:mb-3 mobile:text-base mobile:font-bold tablet:text-2xl tablet:text-slate-50 text-4xl largeK:text-7xl text-font font-semibold mb-7 underline decoration-amber-500">
              Organisation Services
            </h2>
            <p className=" mobile:hover:line-clamp-none mobile:line-clamp-2 mobile:font-normal mobile:text-sm tablet:text-slate-200 font-semibold largeK:text-3xl largeK:mt-14">
              Restore harmony and efficiency to your spaces with our
              Organization Services, decluttering and optimizing your
              environment for maximum functionality and productivity.
            </p>
            <div className=" hidden mobile:block text-white font-bold text-xs">
              See more
            </div>
            <button
              onClick={openModal}
              className=" mobile:font-semibold mobile:p-2 tablet:text-lg mobile:text-sm hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mobile:mt-3 mt-10 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>

        <div>
          <p className=" mobile:px-4 mobile:font-medium font-semibold largeK:text-3xl largeK:mt-14 mobile:text-sm">
            Experience the difference with our comprehensive range of top-tier
            cleaning services tailored to meet your needs. From office and
            commercial spaces to residential properties, event venues, and more,
            our dedicated team ensures unparalleled cleanliness, timeliness, and
            hygiene every time. With our commitment to excellence, we guarantee
            spotless results that exceed your expectations. Don't wait any
            longer to transform your space – book now and discover the
            difference firsthand!
          </p>
          <button
            onClick={openModal}
            className=" tablet:text-lg mobile:text-base hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mt-10 rounded-lg text-xl font-bold"
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
}
