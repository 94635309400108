import React from "react";
import { MdCleaningServices } from "react-icons/md";

export default function AirbnbBody(props) {
  const { openModal } = props;

  return (
    <div className=" py-14 px-10 mobile:px-4">
      <div>
        <div className=" text-center">
          <h2 className=" font-bold text-font text-2xl mb-10 largeK:text-4xl mobile:text-lg">
            Welcome to TG (Canada) Cleaning & Organization Services
          </h2>
          <p className=" text-left text-font largeK:text-3xl largeK:leading-10 mobile:text-sm">
            At TG (Canada) Cleaning & Organization Services, we are dedicated to
            redefining cleanliness and organization with our unparalleled
            commitment to excellence and meticulous attention to detail. With
            years of experience in the industry, we have perfected the art of
            transforming spaces into pristine sanctuaries, ensuring that every
            corner reflects our dedication to quality and customer satisfaction.
            Welcome to a world where cleanliness meets perfection - welcome to
            TG (Canada) Cleaning & Organization Services.
          </p>
          <div className=" text-left mt-12 ml-5">
            <button
              onClick={openModal}
              className=" tablet:text-lg mobile:text-base hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 rounded-lg text-xl font-bold"
            >
              Book Now
            </button>
          </div>
        </div>
        <div className=" mt-14">
          <div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/15/ZrDrq8.image-fx-an-image-of-a-cleaner-putting-on-teal-colored.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Kitchen Cleaning
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  An inviting kitchen is essential for Airbnb guests, offering
                  the convenience of home-cooked meals. A well-equipped kitchen
                  boosts guest satisfaction and influences Airbnb pricing.
                  Here's a checklist for maintaining your vacation home's
                  kitchen.
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Disinfect surfaces
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wipe appliances
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Scrub utensils
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean stove
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wash hoods
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean microwave
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Empty fridge
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Sanitize sink
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Replace trash
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wipe windows
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Mop floors
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/15/ZrDsUq.image-fx-an-image-of-a-cleaner-putting-on-teal-colored-2.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Bathroom Cleaning
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  The bathroom is more than just a functional space; it's a
                  sanctuary for your guests. A pristine and fresh-smelling
                  bathroom can elevate their experience and leave a lasting
                  impression on their Airbnb reviews. Here's how to ensure every
                  detail is perfect:
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Sanitize toilet
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Shine sink and mirrors
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Spot-clean shower
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Scrub shower screen
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Unclog drains
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Dispose of trash
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/15/ZrDX4X.image-fx-an-image-of-a-cleaner-putting-on-teal-colored-3.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Cleaning Living Room
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  As we enter the living room, a space beloved by guests, it
                  deserves special care to evoke an inviting atmosphere. Our
                  meticulous cleaning ensures every surface shines, every piece
                  of furniture gleams, and every corner exudes warmth. Trust TG
                  (Canada) Cleaning & Organization Services to transform your
                  living room into the heart of your home.
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wipe surfaces
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Polish furniture
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Disinfect switches
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Vacuum sofas
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean under furniture
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Mop floors
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Vacuum rugs
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Remove cobwebs
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wipe windows
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Dust baseboards
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/15/Zr7eR6.image-fx-an-image-of-a-cleaner-putting-on-teal-colored-1.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Bedroom Cleaning
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  Your bedroom is a haven for relaxation after a long journey.
                  Providing a clean, inviting space is key to ensuring your
                  guests enjoy a restful night's sleep. Explore our checklist
                  for maintaining a pristine bedroom, tailored to enhance your
                  guests' experience.
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Change linens
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean fixtures
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Disinfect switches
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Vacuum mattress
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clear closets
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean floor
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Polish wood
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Fluff pillows
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Wipe windows
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean curtains
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/15/ZrD1FY.image-fx-an-image-of-a-cleaner-putting-on-teal-colored-4.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Cleaning Utility Room
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  Utility rooms, like laundry spaces, often go unnoticed but are
                  crucial for maintaining a pristine Airbnb. Here's what to
                  prioritize:
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean dryer
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Shine sink/drain
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Restock supplies
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Clean baskets
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Prevent waterlogging
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex tablet:flex-col mb-8">
              <div className=" flex-1 laptop:mr-8">
                <img
                  src="https://i.im.ge/2024/04/16/ZuspMK.image-fx-an-image-of-a-cleaner-putting-on-teal-colored-5.jpeg"
                  alt=""
                  className=" w-[400px] largeK:w-[600px] rounded-2xl"
                />
              </div>
              <div className="flex-[1.9] tablet:ml-0 tablet:flex-1 tablet:mt-9 mobile:mt-4">
                <h2 className=" text-2xl font-semibold mb-7 largeK:text-4xl largeK:font-bold">
                  Re-Stocking
                </h2>
                <p className=" mb-7 largeK:text-3xl largeK:leading-10">
                  Enhance Your Guest Experience: Thoughtfully restocking
                  essential items ensures a delightful stay for your guests.
                  Explore our curated list of commonly provided items:
                </p>
                <div className=" flex flex-wrap items-center">
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Toiletries
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Kitchen Supplies
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Cleaning Supplies
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Bathroom Essentials
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Linens
                  </div>
                  <div className=" w-[200px] laptop:text-xs laptop:w-[150px] largeK:w-[400px] font-semibold flex items-center mr-10 mobile:mr-2 mb-6 largeK:font-bold largeK:text-3xl">
                    <div className=" flex justify-center items-center largeK:text-4xl laptop:text-xl laptop:p-2 laptop:w-10 laptop:h-10 largeK:w-20 largeK:h-20 w-12 h-12 p-3 bg-bgcolor mr-5 rounded-full text-teal text-2xl">
                      <MdCleaningServices />
                    </div>
                    Light Bulbs
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
        <div className=" largeK:text-2xl laptop:text-sm laptop:leading-6 mobile:text-xs">
          At TG (Canada) Cleaning & Organization Services, we understand that
          it's the little details that truly make a space feel like home. From
          meticulously tidying outdoor areas such as porches or balconies to
          ensuring every corner is pristine, we take pride in adding those
          thoughtful finishing touches that create a warm and inviting
          atmosphere for our clients and their guests.
          <br /> Our commitment to delivering high-quality cleaning and
          organization solutions tailored to your unique needs is unwavering.
          With our attention to detail and dedication to excellence, you can
          trust TG (Canada) Cleaning for spaces that are not just clean and
          organized, but also welcoming and comfortable. Experience the
          difference with TG (Canada) Cleaning & Organization Services.
        </div>
        <button
          onClick={openModal}
          className=" mt-12 ml-5 tablet:text-lg mobile:text-base hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 rounded-lg text-xl font-bold"
        >
          Book Now
        </button>
      </div>
    </div>
  );
}
