import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Proccess() {
  return (
    <div className=" p-14 mobile:p-4 mobile:py-14 smallphones:px-2">
      <div className=" flex justify-between items-center">
        <div className=" tablet:text-base tablet:p-3 tablet:w-[150px] tablet:h-[150px] mobile:text-xs mobile:w-[100px] mobile:h-[100px] mobile:p-1 largeK:text-3xl largeK:w-[280px] largeK:h-[280px] text-background font-bold text-xl p-5 rounded-full bg-bgcolor h-[200px] flex items-center justify-center border-teal border-2 w-[200px]">
          Search Services
        </div>
        <div>
          <FaArrowRightLong className=" text-5xl text-amber-500 largeK:text-7xl mobile:text-lg mobile:hidden"/>
        </div>
        <div className=" tablet:text-base tablet:p-3 tablet:w-[150px] tablet:h-[150px] mobile:text-xs mobile:w-[100px] mobile:h-[100px] mobile:p-1 largeK:text-3xl largeK:w-[280px] largeK:h-[280px] text-background font-bold text-xl p-5 rounded-full bg-bgcolor h-[200px] flex items-center justify-center border-teal border-2 w-[200px]">
          Select Service
        </div>
        <div>
          <FaArrowRightLong className=" text-5xl text-amber-500 largeK:text-7xl mobile:text-lg mobile:hidden"/>
        </div>

        <div className=" tablet:text-base tablet:p-3 tablet:w-[150px] tablet:h-[150px] mobile:text-xs mobile:w-[100px] mobile:h-[100px] mobile:p-1 largeK:text-3xl largeK:w-[280px] largeK:h-[280px] text-background font-bold text-xl p-5 rounded-full bg-bgcolor h-[200px] flex items-center justify-center border-teal border-2 w-[200px]">
          Book Service
        </div>
      </div>
    </div>
  );
}
