import React,{useState} from "react";
import AboutUsPage from "./AboutUs/aboutus";
import Airbnb from "./Airbnb/Airbnb";
import "./App.css";
import COntactUs from "./ContactUs/contactUs";
import LandingPage from "./landingPage/landingPage";
import ProductPage from "./ProductPage/poductPage";
import Service from "./Service/service";
import ProductModal from "./productModal";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./404";



function App() {
  
  // PRODUCT MODAL
  
  const [isproductModalOpen, setIsproductModalOpen] = useState(false);
  
  const openproductModal = () => {
    setIsproductModalOpen(true);
  };
  
  const closeproductModal = () => {
    setIsproductModalOpen(false);
  };

  // Adding Product count
  const [addedProduct, setAddedProduct] = useState(0);
  const add = () => {
     setAddedProduct(addedProduct + 1);
   };

  return (
    <div className="App">
      <ProductModal
        isOpen={isproductModalOpen}
        closeModal={closeproductModal}
      />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage openproductModal={openproductModal} isproductModalOpen={isproductModalOpen} addedProduct={addedProduct} add={add}/>}/>
          <Route path="/aboutus" element={<AboutUsPage openproductModal={openproductModal} isproductModalOpen={isproductModalOpen}/>}/>
          <Route path="/contactus" element={<COntactUs openproductModal={openproductModal} isproductModalOpen={isproductModalOpen}/>}/>
          <Route path="/service" element={<Service openproductModal={openproductModal} isproductModalOpen={isproductModalOpen}/>}/>
          <Route path="/airbnb" element={<Airbnb openproductModal={openproductModal} isproductModalOpen={isproductModalOpen}/>}/>
          <Route path="/product" element={<ProductPage openproductModal={openproductModal} isproductModalOpen={isproductModalOpen} addedProduct={addedProduct} add={add}/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
