import React from "react";
import BgimageCU from "../asset/bgImage2.jpg";
import "./contactus.css";
import { Link } from "react-scroll";

export default function ContactHero(props) {
  const { contactForm } = props;

  return (
    <div>
      <div className=" relative flex justify-center ">
        <div className="image-container">
          <img
            src={BgimageCU}
            alt=""
            className=" h-[100vh] w-screen bg-bgcolor mobile:w-auto"
          />
        </div>
        <div className=" absolute h-[100vh] text-center flex flex-col items-center justify-center">
          <h2 className=" font-bold text-3xl text-white mb-6 largeK:text-5xl largeK:mb-14">
            Contact Us
          </h2>
          <p className=" text-white mb-10 largeK:text-3xl largeK:mb-16">
            Connect with us effortlessly. Our Contact Us page is your gateway to
            reaching out, whether it's to inquire, schedule, or simply say
            hello. We're here to listen and assist.
          </p>
          <Link
            activeClass="active"
            to={contactForm}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <button className=" p-5 tablet:p-3 text-white bg-amber-500 font-bold text-xl rounded-lg largeK:text-3xl hover:bg-opacity-50">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
