import React, { useEffect, useState } from "react";
import app from "../firebaseconfiguration";
import { getDatabase, ref, onValue } from "firebase/database";
import ProductDetailsModal from "./productDetailsModal"; // Import the modal component

export default function ProductBody(props) {
  const { add } = props;
  // const {openDetails } = props

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null); // State to store selected product details

  useEffect(() => {
    const database = getDatabase(app);
    const addedProductsInDB = ref(database, "products");

    const fetchData = () => {
      onValue(addedProductsInDB, (snapshot) => {
        const productData = snapshot.val();

        if (productData) {
          const productList = Object.values(productData);
          setProducts(productList.reverse());
        }
      });
    };
    fetchData();

    return () => {
      // Unsubscribe from the database changes
    };
  }, []);

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const handleAddToCart = (product) => {
    // Get existing cart items from local storage
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    // Checking if product already exists in the cart
    const existingItemIndex = existingCartItems.findIndex(
      (pro) => pro.title === product.title
    );

    if (existingItemIndex !== -1) {
      // If the product exists, increment its quantity
      existingCartItems[existingItemIndex].quantity++;
    } else {
      // If the product doesn't exist, add it to the cart with a quantity of 1
      product.quantity = 1;
      existingCartItems.push(product);
    }

    // Update the cart items in local storage
    localStorage.setItem("cart", JSON.stringify(existingCartItems));

    // Set the message content and show the message div
    setMessage("Product added to cart!");
    setShowMessage(true);

    // Automatically hide the message after 3 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const openDetails = (product) => {
    setSelectedProduct(product); // Set the selected product details
  };

  return (
    <div className=" px-14 mobile:px-4 py-16">
      {showMessage && (
        <div className=" w-[200px] rounded-full fixed top-0 left-[40%] z-40 bg-teal font-bold text-sm mobile:text-xs text-white p-3 mobile:p-1 text-center">
          {message}
        </div>
      )}
      <div className="mb-10">
        <h1 className=" text-3xl font-semibold">TG Products</h1>
        <h4 className=" text-lg font-bold">Featured Products</h4>
      </div>
      <div className=" flex justify-start items-center flex-wrap tablet:justify-center">
        {/*  */}
        {products.map((product, index) => (
          <div
            key={index}
            className=" select-none relative mobile:w-[130px] cursor-pointer border-2 rounded-xl w-[200px] largeK:w-[300px] tablet:mb-6 tablet:mr-4 mr-4 mb-4"
          >
            <div
              onClick={() => openDetails(product)} // Pass the product to openDetails function
              className=" mobile:hidden absolute rounded-full bg-amber-500 p-1 font-bold text-white -right-3 -top-3"
            >
              See Details
            </div>
            <div className=" bg-teal mobile:rounded-lg mobile:p-1">
              <img
                src={product.image}
                alt=""
                className=" mobile:rounded-lg mobile:w-[130px] mobile:h-[100px] w-[200px] h-[150px] largeK:w-[300px] largeK:h-[250px]"
              />
              <div className=" hidden mobile:block mobile:text-lg mobile:text-center mobile:text-white font-bold">
                <span>$</span>
                {product.price}
              </div>
            </div>

            {/* mobile view */}
            <div
              onClick={() => openDetails(product)} // Pass the product to openDetails function
              className=" hidden mobile:block"
            >
              <div className=" px-2 pb-2">
                <h4 className=" mobile:font-semibold mobile:text-base mt-3 font-bold text-lg largeK:text-2xl line-clamp-3 mobile:line-clamp-1">
                  {product.title}
                </h4>
                <div className=" mobile:text-xs whitespace-pre-wrap  my-3 text-sm line-clamp-2 largeK:text-xl">
                  {product.description}
                </div>
                <div className=" hidden mobile:block text-[9px] font-extrabold text-center">
                  Tap for more info
                </div>
              </div>
            </div>

            {/* responsivness mobile */}
            <div className=" mobile:hidden px-2 pb-2">
              <h4 className=" mt-3 font-bold text-lg largeK:text-2xl line-clamp-3 mobile:line-clamp-1">
                {product.title}
              </h4>
              <div className=" whitespace-pre-wrap  my-3 text-sm line-clamp-2 largeK:text-xl">
                {product.description}
              </div>
              <div className=" text-2xl font-bold mb-3 largeK:text-4xl largeK:mb-7">
                <span>$</span>
                {product.price}
              </div>
              <div className=" text-center">
                <button
                  onClick={() => {
                    handleAddToCart(product);
                    add();
                  }}
                  className="p-2 border-teal border-2 bg-bgcolor font-bold hover:text-white hover:bg-teal largeK:text-2xl"
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
        {/*  */}

        {/*  */}
      </div>
      <ProductDetailsModal
        isOpen={selectedProduct !== null}
        closeModal={() => setSelectedProduct(null)}
        product={selectedProduct}
        handleAddToCart={handleAddToCart}
        add={add}
      />
    </div>
  );
}
