import React, {useState} from "react";
import Header from "../Header/header";
import Footer from "../footer";
import ProductBody from "./productPgaebody";
import ProductHero from "./productHero";
import ProductDetailModal from "./productDetailsModal";

export default function ProductPage(props){

    const [isproductDetailModalOpen, setIsproductModalOpen] = useState(false);
  
    const openproductDetailModal = () => {
      setIsproductModalOpen(true);
    };
    
    const closeproductDetailModal = () => {
      setIsproductModalOpen(false);
    };

    const{openproductModal, add, addedProduct, isproductModalOpen} = props


    return(
        <div>
            <Header openproductModal={openproductModal} addedProduct={addedProduct} isproductModalOpen={isproductModalOpen} />
            <ProductHero />
            <ProductDetailModal isOpen={isproductDetailModalOpen} closeModal={closeproductDetailModal} />
            <ProductBody openDetails={openproductDetailModal} isproductDetailModalOpen={isproductDetailModalOpen} add={add}/>
            <Footer/>
        </div>
    )
}