import React, { useState } from "react";
import Header from "../Header/header";
import LandingPageHero from "./hero";
import AboutUsSection from "./aboutUsSection";
import ServiceSection from "./service";
import WhyChooseUs from "./whyChooseUs";
import Footer from "../footer";
import QuoteModal from "../quoteModal";
// import ProductSection from "./productSection";

export default function LandingPage(props) {

  // eslint-disable-next-line no-unused-vars
  const{isproductModalOpen, openproductModal, add, addedProduct} = props

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };



  return (
    <div>
      <Header addedProduct={addedProduct} openproductModal={openproductModal} isproductModalOpen={isproductModalOpen} />
      <QuoteModal isOpen={isModalOpen} closeModal={closeModal} />
      
      <div className=" mb-[300px]">
        <LandingPageHero openModal={openModal} isModalOpen={isModalOpen} isproductModalOpen={isproductModalOpen} />
      </div>
      <AboutUsSection />
      {/* <ProductSection add={add} /> */}
      <ServiceSection isModalOpen={isModalOpen} />
      <WhyChooseUs />
      <Footer />
    </div>
  );
}
