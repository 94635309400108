import React from "react";
import mission from "../asset/undraw_shared_goals_re_jvqd.svg";
import Founder from "../asset/TK_image-removebg-preview (1).png";
import BlobI from "../asset/blob/1-removebg-preview (1).png";
import BlobII from "../asset/blob/2-removebg-preview (2).png";
import BlobIII from "../asset/blob/3-removebg-preview (1).png";
import BlobIv from "../asset/blob/4-removebg-preview (1).png";

export default function AbtInfo(props) {

  const {openModal} =props

  return (
    <div className=" relative">
      <img
        src={BlobIv}
        alt=""
        className=" absolute top-[20%] left-[12%] drop-shadow-md z-[-10]"
      />
      <img
        src={BlobIv}
        alt=""
        className=" absolute top-[71%] left-[50%] drop-shadow-md z-[-10]"
      />
      <img
        src={BlobIII}
        alt=""
        className=" absolute top-[50%] right-[12%] drop-shadow-md z-[-10]"
      />
      <img
        src={BlobII}
        alt=""
        className=" absolute top-[40%] left-[37%] drop-shadow-md z-[-10]"
      />
      <img
        src={BlobII}
        alt=""
        className=" absolute bottom-0 right-[16%] drop-shadow-md z-[-10]"
      />
      <img src={BlobI} alt="" className=" absolute z-[-10]" />
      <div className=" px-14 py-16 mobile:p-4">
        <div className=" text-center text-font">
          <h2 className=" font-bold text-2xl underline decoration-amber-500 mb-9 largeK:text-5xl mobile:text-lg">
            About Our Company
          </h2>
          <h1 className=" font-bold text-3xl mb-7 largeK:text-6xl mobile:text-2xl">
            Elevating Cleanliness, Enhancing Lives
          </h1>
        </div>
        <p className="border-b-2 border-font pb-9 mb-14 largeK:text-2xl mobile:text-sm">
          Welcome to Our Company! We're dedicated to delivering top-notch
          cleaning services tailored to your needs. With a focus on excellence
          and customer satisfaction, we strive to transform spaces from ordinary
          to extraordinary. Trust us to bring cleanliness and comfort into your
          life.
        </p>
        <div>
          <h4 className=" text-center font-bold text-2xl underline decoration-amber-500 mb-9 largeK:text-4xl mobile:text-lg">
            Our Mision
          </h4>
          <div className=" flex justify-center items-center mb-14 tablet:justify-normal tablet:items-start tablet:flex-none relative tablet:h-[65vh]">
            <p className=" flex-1 font-semibold largeK:text-2xl mobile:text-sm">
              At Our Company, our mission is clear: to transform spaces and
              enrich lives through our dedication to cleanliness and service
              excellence. We strive to go beyond mere cleaning, aiming to create
              environments that inspire and uplift. With a commitment to
              professionalism, integrity, and customer satisfaction, we endeavor
              to exceed expectations and leave a lasting impact on every space
              we touch. Join us in our mission to elevate cleanliness and
              enhance lives today.
            </p>
            <div className="flex-1 tablet:flex-none tablet:absolute tablet:w-[50vw] bottom-0 right-0 mobile:bottom-14">
              <img src={mission} alt="" />
            </div>
          </div>
          <div>
            <div className=" flex items-center tablet:block relative">
              <div className=" flex-1 text-center font-bold largeK:text-2xl tablet:flex-none tablet:mb-8">
                <div className=" w-fit rounded-t-full pt-5 rounded-es-full h-fit bg-background overflow-hidden tablet:rounded-full tablet:w-[50vw]">
                  <img src={Founder} alt="" />
                </div>
                <div className=" mt-4 tablet:font-normal ">
                  Temitope K. Fagboro <div>CEO/Founder</div>
                  <div> TOPMAN GALAXY (Canada)</div>
                </div>
              </div>
              <div className="flex-1 laptop:pl-5 tablet:pl-0 tablet:flex-none">
                <h4 className=" font-bold text-xl mb-7 largeK:text-4xl mobile:text-base mobile:mb-3">
                  About Founder
                </h4>
                <p className="largeK:text-2xl mobile:text-sm">
                  TG (Canada) Cleaning and Organization Services began with a
                  humble vision, sparked by the Founder of TOPMAN GALAXY
                  (Canada) Ltd. With a profound love for cleanliness and a
                  deep-seated belief in its power to create safe and welcoming
                  environments, the Founder embarked on a journey that
                  transcended mere cleaning tasks. To him, cleaning wasn't just
                  a chore; it was a state of mind, an emotion, a feeling he
                  wished to share with the world.
                </p>
              </div>
            </div>
            <div className=" font-semibold pt-14 largeK:text-2xl mobile:text-sm">
              As a Certified 5S Cleaning and Organization Standards practitioner
              (Sort, Set in Order, Shine, Standardize, and Sustain), the
              Founder's commitment to cleanliness was unwavering. In 2024, he
              brought this vision to life through TG Canada Cleaning and
              Organization. From commercial warehouses and offices to
              residential spaces and Airbnb accommodations, the company
              delivered high-quality cleaning services. The Founder personally
              interviewed each service partner, ensuring they resonated with the
              philosophy of cleaning as a transformative experience. Always
              looking ahead, TG Canada Cleaning and Organization seeks to
              partner with individuals who share its mission of creating clean
              and inviting spaces for all. With aspirations beyond conventional
              cleaning services, who knows? Perhaps one day, TG Canada Cleaning
              and Organization will have its own Netflix series. Join us on this
              journey towards cleaner spaces and brighter futures.
            </div>
            <button onClick={openModal} className="bg-amber-500 p-3 mt-7 font-bold text-white text-xl rounded-lg hover:bg-opacity-50 largeK:p-5 largeK:text-3xl largeK:mt-20 smallphones:text-base">
              Get A Quote
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
}
