import React from "react";
import theimg from "../asset/The_image-removebg-preview.png";
import airnbnb from "../asset/undraw_apartment_rent_o-0-ut.svg";

export default function AirbnbHero(props) {
  const { openModal } = props;

  return (
    <div className=" bg-bgcolor">
      <div className=" relative">
        <img
          src={theimg}
          alt=""
          className=" w-screen drop-shadow-xl h-[95vh]"
        />
        <h1 className=" absolute text-7xl largeK:text-9xl laptop:text-5xl smallphones:text-3xl laptop:top-[25%] mobile:text-4xl w-[70vw] font-bold text-white drop-shadow-md decoration-bgcolor top-[15%] right-[4%] text-right">
          Transform Your Airbnb into a Welcoming Haven
        </h1>
        <button
          onClick={openModal}
          className=" absolute right-16 top-[50vh] text-white tablet:text-lg mobile:text-base hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-teal rounded-lg text-xl font-bold"
        >
          Book Now
        </button>
        <img
          src={airnbnb}
          alt=""
          className=" w-[45vw] absolute bottom-0 left-[3%]"
        />
      </div>
    </div>
  );
}
