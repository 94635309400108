import React from "react";
import collage from "../asset/Untitled-2.jpg";
import { FaArrowDownLong } from "react-icons/fa6";

import "./service.css";

const ServiceHero = (props) => {

  const {openModal} = props

  return (
    <div className="image-collage bg-bgcolor">
      <div className=" relative flex justify-center items-center">
        <div className="image-container">
          <img
            src={collage}
            alt=""
            className=" w-auto h-auto mobile:h-[85vh] largeK:w-[100vw]"
          />
        </div>
        <div className=" w-[100vw] h-fit absolute text-center bg-black bg-opacity-35 p-5">
          <h1 className=" text-3xl font-bold text-white mb-7 largeK:text-5xl largeK:mb-14 tablet:text-xl tablet:mb-5">Our Services</h1>
          <h2 className=" text-4xl font-bold text-white underline decoration-amber-500 drop-shadow-md mb-7 largeK:text-7xl tablet:text-2xl tablet:mb-5 mobile:text-xl">Sparkling Clean Solutions</h2>
          <p className=" font-bold text-white text-lg largeK:mt-14 largeK:text-2xl tablet:text-sm mobile:text-xs ">
            Experience the difference with our meticulous cleaning services.
            From homes to offices, we ensure every space shines with cleanliness
            and comfort.
          </p>
          <button onClick={openModal} className=" tablet:text-lg mobile:text-base hover:bg-opacity-50 largeK:text-3xl largeK:p-5 p-3 bg-amber-500 mt-10 rounded-lg text-xl font-bold">
                Book Now
              </button>
        </div>
        <div className=" text-amber-500 absolute bottom-[35%] laptop:bottom-[25%] tablet:top-[90%] tablet:bottom-auto bg-amber-500 largeK:p-5 p-3 rounded-full bg-opacity-45">
        <FaArrowDownLong className="largeK:text-5xl text-3xl" />
        </div>
      </div>
    </div>
  );
};

export default ServiceHero;
