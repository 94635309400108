// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5VUwap7S6p-cG90KO12OW_k4Q9XEa_lw",
  databaseURL: "https://topman-galaxy-default-rtdb.europe-west1.firebasedatabase.app", // Update this URL
  authDomain: "topman-galaxy.firebaseapp.com",
  projectId: "topman-galaxy",
  storageBucket: "topman-galaxy.appspot.com",
  messagingSenderId: "242354829271",
  appId: "1:242354829271:web:0975b2aac42915761d7d92",
  measurementId: "G-P9TD3XRTYB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const auth = getAuth(app);
signInAnonymously(auth).catch((error) => {
  // Handle anonymous authentication error
});

export default app