import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className=" flex justify-center items-center w-screen h-screen">
      <div className=" text-center">
      <h1 className=" largeK:text-9xl text-7xl mobile:text-2xl font-extrabold flex flex-col">ERROR 404 <span className=" text-4xl largeK:text-6xl mobile:text-xl">PAGE NOT FOUND</span></h1>
      <div className=" largeK:text-4xl text-2xl mobile:text-sm mt-3 largeK:mt-9">Sorry, page you are looking for does not exist.</div>
      <Link to="/">
      <div className=" bg-amber-500 font-bold p-3 text-2xl mobile:text-base rounded-md text-white mt-3 largeK:mt-9 largeK:p-5 largeK:text-5xl">
        GO HOME
      </div>
      </Link>
      </div>
    </div>
  );
}
