import React from "react";
import Header from "../Header/header";
import Footer from "../footer";
import ContactHero from "./contactusHero";
import ContactForm from "./contactForm";
import StraightContact from "./straightContact";
import Map from "./contactusMap";


export default function COntactUs(props){

    const{openproductModal, isproductModalOpen} = props

    return(
        <div>
            <Header openproductModal={openproductModal} isproductModalOpen={isproductModalOpen}/>
            <ContactHero contactForm="contactForm"/>
            <div className=" p-14 mobile:p-5 flex justify-between items-center tablet:flex-col tablet:items-stretch" id="contactForm">
                <div className=" flex-1 flex justify-center tablet:mb-[50px]">
                <StraightContact/>
                </div>
                <div className=" flex-1">
                <ContactForm/>
                </div>
            </div>
                <Map/>
            <Footer/>
        </div>
    )
}