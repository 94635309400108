import React from "react";
import "./hero.css";
import HeroImage from "../asset/60711-removebg-preview.png";
import Bg from "../asset/bgimage-removebg-previewII.png";

export default function LandingPageHero(props) {
  const { openModal, isModalOpen, isproductModalOpen } = props;

  return (
    <div className=" shadow-lg bg-bgcolor text-font h-[60vh] largeK:h-[72vh] relative">
      <div className=" flex px-14 py-5 w-[55vw] sm:w-full sm:px-4 largeK:px-32 largeK:py-28">
        <div>
          <h1 className="text-2xl font-bold mb-4 largeK:text-6xl tablet:text-lg">TG (canada) <span className=" text-amber-500">cleaning service</span></h1>
          <h1 className=" text-5xl font-bold mb-14 largeK:text-9xl sm:text-4xl tablet:text-3xl">
            Discover <span className=" text-amber-500">Exceptional</span>{" "}
            Cleaning Solutions
          </h1>
          <p className=" w-[60%] mb-10 largeK:text-3xl laptop:text-sm tablet:text-xs">
            Experience pristine cleanliness and impeccable service with our
            professional cleaning expertise. Book now for a spotless space that
            shines.
          </p>
          {/* <Link to="/service"> */}
          <button
            onClick={openModal}
            className={` ${
              isModalOpen || isproductModalOpen ? "z-0" : "z-50"
            } sm:py-3 sm:px-3 sm:text-base smallphones:text-sm laptop:absolute largeK:text-4xl largeK:py-9 rounded-lg border-2 py-6 px-6 mt-6 tablet:mt-0 tablet:text-lg tablet:py-4 font-bold text-2xl shadow-md bg-amber-500 text-[#2a2444] hover:bg-opacity-35`}
          >
            Book A Service
          </button>
          {/* </Link> */}
        </div>
        <div
          className={`border-radius bg-teal ${
            isModalOpen || isproductModalOpen ? "z-0" : "z-50"
          } absolute bottom-[-130px] right-0 overflow-hidden`}
        >
          <img
            src={HeroImage}
            alt=""
            className=" h-[70vh] w-[50vw] tablet:h-[55vh] tablet:w-[45vw] smallphones:h-[40vh] smallphones:w-[50vw]"
          />
        </div>
      </div>
      <img
        src={Bg}
        alt=""
        className=" w-screen absolute bottom-[-240px] h-60 z-0 largeK:bottom-[-230px] drop-shadow-lg"
      />
      <div className=" rounded-full border-8 w-28 h-28 opacity-60 border-teal absolute bottom-[-100px] left-[40%] largeK:w-40 largeK:h-40 tablet:w-20 tablet:h-20 sm:left-[80px]"></div>
      <div className=" rounded-full border-8 w-20 h-20 opacity-60 border-teal absolute bottom-[150px] left-[44%] largeK:w-36 largeK:h-36 tablet:w-16 tablet:h-16 sm:left-[200px]"></div>
      <div className=" rounded-full border-8 w-14 h-14 opacity-60 border-teal absolute top-[50px] left-[55%] tablet:w-12 tablet:h-12 sm:left-[290px] sm:top-[100px] smallphones:hidden"></div>
    </div>
  );
}
