import React, { useState} from "react";
import "./lPheader.css";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsCart4 } from "react-icons/bs";

const BurgerMenu = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {openproductModal, totalProducts, isproductModalOpen } = props;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <div>
      <div className="burger-menu relative">
        <span className=" font-bold text-xs absolute -top-2 px-1 left-0 bg-amber-500 rounded-full">
          {totalProducts}
        </span>
        <div className="burger-icon" onClick={toggleMenu}>
          <div
            className={`burger-line bg-yellow-500 ${menuOpen ? "openI" : ""}`}
          />
          <div
            className={`burger-line bg-yellow-500 ${menuOpen ? "openII" : ""}`}
          />
          <div
            className={`burger-line bg-yellow-500 ${menuOpen ? "openIII" : ""}`}
          />
        </div>
        {menuOpen && (
          <div className={` ${ isproductModalOpen? "hidden" : ""} menu-list p-5 w-80 text-center bg-font bg-opacity-70 text-white h-[90vh] rounded-md font-bold shadow-md`}>
            <Link to="/">
              <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                Home
              </div>
            </Link>
            <Link to="/aboutus">
              <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                About
              </div>
            </Link>
            <Link to="/service">
              <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                Services
              </div>
            </Link>
            <Link to="/airbnb">
              <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                Airbnb
              </div>
            </Link>
            <Link to="/product">
                <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                  Products
                </div>
              </Link>
            <Link to="/contactus">
              <div className=" h-16 flex items-center justify-center border-b-2 border-yellow-600 hover:bg-yellow-600">
                Contact
              </div>
            </Link>
            <div
              onClick={openproductModal}
              className=" w-[50px] mt-5 text-xl bg-teal mr-4 rounded-full p-3 font-bold relative hover:bg-amber-500 cursor-pointer"
            >
              <div>
                <span className=" font-bold text-xs absolute top-2 right-1 bg-amber-500 rounded-full px-1">
                  {totalProducts}
                </span>
                <BsCart4 />
              </div>
            </div>
            <div className=" text-yellow-300 mt-10 text-3xl">
              <a href="tel:4169314368">
                <FaPhoneAlt className="" />
              </a>
            </div>
          </div>
        )}
        {menuOpen && <div className="overlay" onClick={toggleMenu} />}
      </div>
    </div>
  );
};

export default BurgerMenu;
