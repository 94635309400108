/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import TkLogo from "../asset/TG reg PNG logo.png";
import BurgerMenu from "./headerMenu";
import { BsCart4 } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Header(props) {
  const { addedProduct, openproductModal, isproductModalOpen } = props;

  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];

    if (Array.isArray(existingCartItems) && existingCartItems.length > 0) {
      const total = existingCartItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      );

      if (!isNaN(total)) {
        setTotalProducts(total);
      } else {
        console.error("Total is NaN");
      }
    } else {
      console.error("No existing cart items found");
    }
    // setTotalProducts(total);
  }, [addedProduct]);

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownOpen = () => {
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <header className=" py-3 px-5 bg-bgcolor largeK:py-5 largeK:px-7 border-b-2 border-font">
        <nav className=" flex justify-between items-center">
          <div className=" flex-1 flex">
            <a href="#">
              <img
                src={TkLogo}
                alt="Topman_Galaxy_Logo"
                className=" w-16 h-10 largeK:w-32 largeK:h-16"
              />
            </a>
          </div>
          <div className=" flex-[2.4] tablet:hidden">
            <div className=" flex justify-between text-font font-bold largeK:text-3xl">
              <Link to="/">
                <div className=" hover:text-yellow-500 underline-offset-2 hover:underline cursor-pointer">
                  HOME
                </div>
              </Link>
              <Link to="/aboutus">
                <div className=" hover:text-yellow-500 underline-offset-2 hover:underline cursor-pointer">
                  ABOUT US
                </div>
              </Link>
                <div
                 onMouseEnter={handleDropdownOpen}
                 onMouseLeave={handleDropdownClose}
                  className=" flex items-center underline-offset-2 hover:underline cursor-pointer"
                >
                  <span className="hover:text-yellow-500">
                  OUR SERVICES
                  </span>
                  <span className=" text-2xl font-bold text-amber-500">
                    <IoMdArrowDropdown />
                  </span>
                  {isOpen && (
                    <div  onMouseEnter={handleDropdownOpen}
                    onMouseLeave={handleDropdownClose}
                    className="absolute top-[45px] z-50 border border-font rounded-md p-4 px-10 bg-font bg-opacity-55">
                      <Link to="/service">
                      <div className=" mb-3 hover:text-amber-500 text-white">TG-Service</div>
                      </Link>
                      <Link to="/airbnb">
                      <div className="hover:text-amber-500 text-white">Airbnb</div>
                      </Link>
                    </div>
                  )}
                </div>
              <Link to="/product">
                <div className=" hover:text-yellow-500 underline-offset-2 hover:underline cursor-pointer">
                  PRODUCTS
                </div>
              </Link>
              <Link to="/contactus">
                <div className=" hover:text-yellow-500 underline-offset-2 hover:underline cursor-pointer">
                  CONTACT US
                </div>
              </Link>
            </div>
          </div>
          <div className=" text-white flex-1 flex justify-end largeK:text-3xl  tablet:hidden">
            <div
              onClick={openproductModal}
              className=" bg-teal mr-4 rounded-full p-3 font-bold relative hover:bg-amber-500 cursor-pointer"
            >
              <div>
                <span className=" font-bold text-xs absolute px-1 top-2 right-1 bg-amber-500 rounded-full">
                  {totalProducts}
                </span>
                <BsCart4 />
              </div>
            </div>
            <div className=" flex rounded-full bg-amber-500 p-3 hover:bg-teal cursor-pointer">
              <a href="tel:+14169314368">
                <FaPhoneAlt className=" mr-0 hover:text-white" />
              </a>
            </div>
          </div>
          <div className=" tabletsm:hidden">
            <BurgerMenu
              totalProducts={totalProducts}
              openproductModal={openproductModal}
              isproductModalOpen={isproductModalOpen}
            />
          </div>
        </nav>
      </header>
    </div>
  );
}
