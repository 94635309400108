import React from "react";
import { FaPhone } from "react-icons/fa6";
import { TbMailFilled } from "react-icons/tb";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

export default function StraightContact() {
  return (
    <div className=" largeK:pb-44">
      <div>
        <div className=" text-center mb-7 text-2xl text-font font-bold underline decoration-amber-500 largeK:text-4xl mobile:text-xl">
          Click on Any to Reach Us
        </div>
        <div>
          <div className=" w-[300px] font-bold text-sm bg-teal hover:bg-amber-500 hover:text-white mb-10 px-3 py-4 rounded-lg largeK:w-[450px] largeK:px-4 largeK:py-5 largeK:text-2xl mobile:text-sm mobile:w-[280px]">
            <a href=".com" className=" flex items-center">
              <div className=" text-xl rounded-full mr-5 bg-bgcolor p-3 text-background largeK:text-3xl mobile:text-lg">
                <FaPhone />
              </div>
              +1 416 931 4368
            </a>
          </div>
          <div className=" w-[300px] font-bold text-sm bg-teal mb-10 px-3 py-4 rounded-lg hover:bg-amber-500 hover:text-white largeK:w-[450px] largeK:px-4 largeK:py-5 largeK:text-2xl mobile:text-sm mobile:w-[280px]">
            <a
              href="mailto:topmangalaxy@gmail.com"
              className="flex items-center"
            >
              <div className=" text-xl rounded-full mr-5 bg-bgcolor p-3 text-background largeK:text-3xl mobile:text-lg">
                <TbMailFilled />
              </div>
              topmangalaxy@gmail.com
            </a>
          </div>
          <div className=" flex mb-10 w-[300px] justify-between items-center px-3 largeK:w-[450px] mobile:w-[280px]">
            <div className=" text-2xl text-background rounded-full p-3 bg-bgcolor hover:text-white hover:bg-amber-500 largeK:text-5xl">
              <a href="https://x.com/TGalaxyCleaning?t=daVZtH3hvI2fhrtfTKWWVQ&s=08">
                <FaSquareXTwitter />
              </a>
            </div>
            <div className=" text-2xl text-background rounded-full p-3 bg-bgcolor hover:text-white hover:bg-amber-500 largeK:text-5xl">
              <a href="https://www.facebook.com/profile.php?id=61557686035812&mibextid=ZbWKwL">
                <FaFacebookSquare />
              </a>
            </div>
            <div className=" text-2xl text-background rounded-full p-3 bg-bgcolor hover:text-white hover:bg-amber-500 largeK:text-5xl">
              <a href="https://www.instagram.com/topmangalaxycleaning?utm_source=qr&igsh=dmR5djloeTRsNmg1">
                <RiInstagramFill />
              </a>
            </div>
          </div>
          <div className=" flex items-center w-[300px] font-bold text-sm bg-teal px-3 py-5 rounded-lg largeK:w-[450px] largeK:px-4 largeK:py-5 largeK:text-2xl mobile:text-sm mobile:w-[280px]">
            <div className=" text-xl rounded-full mr-5 bg-bgcolor p-3 text-background largeK:text-3xl mobile:text-lg">
              <FaLocationDot />
            </div>
            3 Knightsbridge Road, Brampton, Ontario. L6T 3X3{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
