import React from "react";

export default function ContactMap() {
  return (
    <div>
      <iframe
      title="Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.6283299831553!2d-79.71810412395583!3d43.71827247109905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3df0feef63dd%3A0x90aceeed1c8c545f!2s3%20Knightsbridge%20Rd%2C%20Brampton%2C%20ON%20L6T%203X3%2C%20Canada!5e0!3m2!1sen!2sng!4v1714638762761!5m2!1sen!2sng"
        width="100%"
        height="500"
        // eslint-disable-next-line react/style-prop-object
        style={{border:0}}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
} 
