import React from "react";
import { GrClearOption } from "react-icons/gr";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import AirbnbImg from "../asset/Designer-removebg-preview.png";
import "./hero.css";
import { Link } from "react-router-dom";

export default function ServiceSection(props) {
  const { isModalOpen } = props;

  return (
    <div className=" px-14 bg-bgcolor py-16 pb-0 text-font mobile:px-4 largeK:px-20">
      <h2 className=" text-4xl font-bold text-center mb-6 largeK:text-6xl tablet:text-3xl mobile:text-2xl">
        Get The Best Fit Services
      </h2>
      <p className=" text-center text-lg mb-20 largeK:text-2xl tablet:text-sm mobile:text-xs">
        We excel in our craft because we love what we do. Experience the
        best-in-class cleaning and janitorial services tailored to your needs.
      </p>
      <div className=" tablet:overflow-x-scroll">
        <div className=" flex flex-wrap justify-center items-center tablet:flex-nowrap tablet:justify-start">
          <div
            style={{
              backgroundImage:
                "url('https://i.im.ge/2024/05/08/ZVjxFF.image-fx-an-image-that-shows-the-background-image-of-a.jpeg')",
            }}
            className=" bg-cover bg-center border-2 w-[350px] mr-10 bg-slate-50 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]"
          >
            <div className=" mb-5 text-3xl bg-bgcolor text-teal p-3 rounded-full largeK:text-6xl largeK:p-6 largeK:mb-16">
              <GrClearOption />
            </div>
            <div className=" bg-teal rounded-2xl py-2 bg-opacity-70 text-slate-200">
              <h4 className=" laptop:text-lg largeK:text-3xl font-bold text-xl mb-4 tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                Office & Commercial cleaning
              </h4>
              <div className=" laptop:text-sm largeK:text-xl mobile:text-xs">
                Efficiently maintain your workspace with our professional office
                and commercial cleaning services.
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://i.im.ge/2024/05/08/ZVjCk6.image-fx-a-loaded-image-that-shows-the-background-imag.jpeg')",
            }}
            className=" bg-cover bg-center border-2 w-[350px] mr-10 bg-slate-50 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]"
          >
            <div className=" mb-5 text-3xl bg-bgcolor text-teal p-3 rounded-full largeK:text-6xl largeK:p-6 largeK:mb-16">
              <GrClearOption />
            </div>
            <div className=" bg-teal rounded-2xl py-2 bg-opacity-70 text-slate-200">
              <h4 className=" laptop:text-lg largeK:text-3xl font-bold text-xl mb-4 tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                Residential Cleaning
              </h4>
              <div className=" laptop:text-sm largeK:text-xl">
                Transform your home into a haven of cleanliness with our
                meticulous residential cleaning solutions.
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://i.im.ge/2024/05/08/ZVjAPz.image-fx-a-loaded-image-that-shows-the-background-imag-1.jpeg')",
            }}
            className=" bg-cover bg-center border-2 w-[350px] mr-10 bg-slate-50 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]"
          >
            <div className=" mb-5 text-3xl bg-bgcolor text-teal p-3 rounded-full largeK:text-6xl largeK:p-6 largeK:mb-16">
              <GrClearOption />
            </div>
            <div className=" bg-teal rounded-2xl py-2 bg-opacity-70 text-slate-200">
              <h4 className=" laptop:text-lg largeK:text-3xl font-bold text-xl mb-4 tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                Move in & Move out Cleaning
              </h4>
              <div className=" laptop:text-sm largeK:text-xl">
                Make moving hassle-free with our comprehensive move-in and
                move-out cleaning services.
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://i.im.ge/2024/05/08/ZVjwQx.image-fx-a-loaded-image-that-shows-the-background-imag-4.jpeg')",
            }}
            className=" bg-cover bg-center border-2 w-[350px] mr-10 bg-slate-50 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]"
          >
            <div className=" mb-5 text-3xl bg-bgcolor text-teal p-3 rounded-full largeK:text-6xl largeK:p-6 largeK:mb-16">
              <GrClearOption />
            </div>
            <div className=" bg-teal rounded-2xl py-2 bg-opacity-70 text-slate-200">
              <h4 className=" laptop:text-lg largeK:text-3xl font-bold text-xl mb-4 tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                Event Venue Cleaning
              </h4>
              <div className=" laptop:text-sm largeK:text-xl">
                Ensure your event venue sparkles from floor to ceiling with our
                thorough cleaning expertise.
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage:
                "url('https://i.im.ge/2024/05/08/ZVjNxJ.image-fx-a-loaded-image-that-shows-the-background-imag-7.jpeg')",
            }}
            className=" bg-cover bg-center border-2 w-[350px] mr-10 bg-slate-50 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]"
          >
            <div className=" mb-5 text-3xl bg-bgcolor text-teal p-3 rounded-full largeK:text-6xl largeK:p-6 largeK:mb-16">
              <GrClearOption />
            </div>
            <div className=" bg-teal rounded-2xl py-2 bg-opacity-70 text-slate-200">
              <h4 className=" laptop:text-lg largeK:text-3xl font-bold text-xl mb-4 tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                Janitorial Services
              </h4>
              <div className=" laptop:text-sm largeK:text-xl">
                Keep your premises pristine with our reliable and dedicated
                janitorial services.
              </div>
            </div>
          </div>
          <Link to="/service">
            <div className=" bg-background border-2 w-[350px] mr-10 text-center shadow-md mb-10 p-5 h-[300px] flex flex-col justify-center items-center rounded-2xl largeK:w-[500px] largeK:h-[450px] laptop:w-[300px] laptop:h-[250px]">
              <div className=" mb-5 text-2xl text-white font-semibold  tablet:w-[300px] mobile:w-[240px] mobile:text-base">
                <div className=" text-3xl mb-3">
                  <span className=" text-amber-500">Click</span> to Explore
                </div>
                Discover the full spectrum of our services.
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className=" hidden tablet:flex justify-center text-2xl mb-11">
        <FaArrowAltCircleLeft className=" mr-5 text-amber-500" />
        <FaArrowAltCircleRight className=" text-amber-500" />
      </div>
      <div className=" mt-[300px]">
        <div className=" bg-amber-500 h-[400px] flex p-7 largeK:h-[500px]">
          <div className=" flex-[1.5] tablet:flex-none tablet:w-full">
            <h2 className=" text-4xl font-bold mb-10 largeK:text-7xl mobile:text-2xl">
              Transform your Airbnb into a welcoming haven
            </h2>
            <p className=" font-semibold mb-10 largeK:text-2xl mobile:text-xs">
              From meticulous kitchen and bathroom cleaning to thoughtful
              restocking of essential items, we ensure your guests feel at home.
              Trust us to exceed your expectations, leaving your space pristine
              and inviting. Discover the difference with TG (Canada) Cleaning
              today! see more details
            </p>
            <Link to="/airbnb">
              <button className=" mobile:text-xl mobile:p-3 bg-teal rounded-lg p-4 text-white font-bold text-2xl shadow-md hover:bg-opacity-70 border-teal border-2 largeK:text-3xl">
                See More
              </button>
            </Link>
          </div>
          <div className=" relative flex-1 tablet:flex-none tablet:w-full tablet:hidden">
            <div className=" airbnbimg bg-teal w-full h-full absolute bottom-0 left-0">
              <img
                src={AirbnbImg}
                alt=""
                className={` bottom-[-27px] left-0 w-full ${
                  isModalOpen ? "z-0" : "z-50"
                } absolute`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
