import React from "react";
import bgprd from "../asset/prd bg.png";

export default function ProductHero() {
  return (
    <div>
      <div className=" bg-teal h-[50vh] overflow-hidden mobile:h-[30vh]">
        <img src={bgprd} alt="" className=" w-full h-auto" />
      </div>
      <div className=" py-12 pl-14 mobile:pl-4">
        Welcome to <strong> TG Canada</strong> , your trusted partner for premier cleaning
        services, janitorial supplies, and sanitation solutions in Toronto, ON.
        <br />
        <br />
        At TG Canada, we're committed to delivering exceptional quality and
        <b> customer satisfaction.</b> Our dedicated team ensures that businesses have
        access to top-tier cleaning supplies and efficient inventory management
        services, allowing them to focus on their core operations with
        confidence.
        <br />
        <br />
        Partner with TG Canada for reliable, cost-effective solutions tailored
        to your unique needs. Experience world-class quality and peace of mind
        with TG Canada.
      </div>
    </div>
  );
}
