import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { FaFacebookSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

export default function Footer() {
  return (
    <footer>
      <div className=" bg-font px-14 py-5 mobile:px-4 text-white">
        <div className=" flex mobile:justify-center items-center">
          <div className=" mr-8 text-2xl largeK:text-4xl hover:text-amber-500">
            <a href="https://x.com/TGalaxyCleaning?t=daVZtH3hvI2fhrtfTKWWVQ&s=08">
            <FaSquareXTwitter />
            </a>
          </div>
          <div className=" mr-8 text-2xl largeK:text-4xl hover:text-amber-500">
            <a href="https://www.facebook.com/profile.php?id=61557686035812&mibextid=ZbWKwL">
              <FaFacebookSquare />
            </a>
          </div>
          <div className=" mr-8 text-2xl largeK:text-4xl hover:text-amber-500">
            <a href="https://www.instagram.com/topmangalaxycleaning?utm_source=qr&igsh=dmR5djloeTRsNmg1">
            <RiInstagramFill />
            </a>
          </div>
          <div className=" mr-8 text-2xl largeK:text-4xl hover:text-amber-500">
            <a href="mailto:topmangalaxy@gmail.com">
            <BiLogoGmail />
            </a>
          </div>
        </div>
        <div className="text-center laptop:text-sm mt-3 mobile:text-xs">
          © All rights reserved
        </div>
      </div>
    </footer>
  );
}
