import React from "react";
import "./aboutus.css";

import BgImageTool from "../asset/bgimageteam.jpg";

export default function AbtHero(props) {
  const { openModal } = props;

  return (
    <div className=" h-[91vh] relative overflow-hidden ">
      <div className="image-container">
        <img
          src={BgImageTool}
          alt=""
          className=" h-[100vh] w-screen bg-bgcolor mobile:w-auto"
        />
      </div>
      <div className=" ">
        <div className=" text-center flex justify-center items-center flex-col absolute top-[40%] left-[3.5%] largeK:top-[35%] largeK:left-[1.5%] laptop:left-[1%]">
          <h2 className=" text-white text-3xl font-bold mb-7 largeK:text-5xl largeK:mb-20 laptop:text-2xl mobile:text-base">
            ABOUT US
          </h2>
          <p className=" text-amber-500 drop-shadow-lg font-bold text-4xl uppercase largeK:text-7xl laptop:text-3xl mobile:text-xl smallphones:text-lg">
            From Dusty to Spotless, we assure you a pristine space in record
            time.
          </p>
          <p className="text-white mt-7 largeK:text-2xl largeK:mt-20 tablet:w-[500px] tablet:text-sm mobile:text-xs mobile:w-[300px] ">
            Celebrate cleanliness and efficiency with our promise to transform
            dusty spaces into spotless sanctuaries swiftly and reliably.
          </p>

          <button
            onClick={openModal}
            className=" bg-amber-500 p-3 mt-7 font-bold text-white text-xl rounded-lg hover:bg-opacity-50 largeK:p-5 largeK:text-3xl largeK:mt-20 smallphones:text-base"
          >
            Get A Quote
          </button>
        </div>
      </div>
    </div>
  );
}
