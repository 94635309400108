import React from "react";
import Modal from "react-modal";
import { IoMdArrowRoundBack } from "react-icons/io";


Modal.setAppElement("#root");

const ProductDetailModal = ({
  isOpen,
  closeModal,
  product,
  handleAddToCart,
  add
}) => {
  const addToCart = () => {
    add()
    handleAddToCart(product); // Call the handleAddToCart function passed from the parent component
  };

  if (!product) {
    return null; // If product is not defined, return null or handle the case accordingly
  }

  const { image, title, description, price} = product;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Product Modal"
    >
      <div className=" relative">
      <button
        onClick={closeModal}
        className=" fixed z-40 top-[10px] left-[25px] mobile:text-xs bg-amber-500 hover:bg-gray-400 text-white mt-4 font-bold text-lg p-2 rounded-full"
      >
        <IoMdArrowRoundBack />
      </button>
      <div className=" flex justify-center relative">
        <div className=" border p-2">
          <div className="mb-4">
            <img src={image} alt="" className=" w-[200px] h-[200px]" />
          </div>
          <div className=" font-bold text-2xl mobile:text-base mb-2 largeK:text-6xl">
            <h1>{title}</h1>
          </div>
          <div className=" mb-4 bg-teal text-white w-fit p-2 rounded-md largeK:text-4xl">
            Price : <span>$</span>
            <span className=" font-bold">{price}</span>
          </div>
          <div className=" whitespace-pre-wrap mobile:text-xs largeK:text-xl">{description}</div>
          {/* <div>{quantity}</div> */}
          <button
            onClick={addToCart}
            className=" mt-5 p-2 border-teal border-2 bg-bgcolor font-bold hover:text-white hover:bg-teal largeK:text-2xl"
          >
            Add to Cart
          </button>
        </div>
      </div>
      <button
        onClick={closeModal}
        className=" mobile:text-xs bg-red-600 hover:bg-gray-400 text-white mt-4 font-bold py-2 px-4 rounded"
      >
        Close
      </button>
      </div>
    </Modal>
  );
};

export default ProductDetailModal;
