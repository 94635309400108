import React, { useState } from "react";
import Header from "../Header/header";
import Footer from "../footer";
import AbtHero from "./AbtHero";
import AbtInfo from "./abtInfo";
import ServiceSection from "../landingPage/service";
import QuoteModal from "../quoteModal";

export default function AboutUsPage(props) {
  const { openproductModal,isproductModalOpen } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header openproductModal={openproductModal} isproductModalOpen={isproductModalOpen} />
      <QuoteModal isOpen={isModalOpen} closeModal={closeModal} />

      <AbtHero openModal={openModal} />
      <AbtInfo openModal={openModal} />
      <ServiceSection />
      <Footer />
    </div>
  );
}
